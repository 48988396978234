import React, { useEffect, useRef } from 'react';

import { MapDot } from 'app/components/Common/svg-components';
import { useEpisode, useSession } from 'app/hooks';
import { TestID } from 'app/util/test-id';

import { formatCoords } from '../helpers';
import blueDot from './BlueDot.svg';
import goldPin from './GoldPin.svg';
import LegendBar from './LegendBar';
//@ts-ignore
import Marker from './Marker';
import { Container, MapPin, MapView, MapViewPositionWrapper } from './styles';
import silverPin from './SilverPin.svg';
import { Platform } from 'react-native';
import theme from 'app/util/theme';

interface Marker {
  id: number;
  isSilver: boolean;
  lat: number;
  lng: number;
}
interface Props {
  markers: Marker[];
}

/**
 * Displays a background map view with hospital markers.
 * The map center can be offset to accomodate various
 * device sizes.
 */
const Map = ({ markers }: Props) => {
  const { episode } = useEpisode();
  const mapView = useRef();

  const episodeLocation = episode.location;

  const mapProviderMarkers = markers.map((marker) =>
    marker && marker.lat && marker.lng ? (
      <Marker
        id={marker.id}
        key={marker.id}
        coordinates={formatCoords(marker)}
        icon={marker.isSilver ? silverPin : goldPin}
        innerIcon={<MapPin tier={marker.isSilver ? 'silver' : 'gold'} />}
      />
    ) : null
  );

  const episodeLocationMarker = episodeLocation ? (
    <Marker
      id="user-location"
      key="user-location"
      coordinates={formatCoords(episodeLocation)}
      icon={blueDot}
      innerIcon={<MapDot />}
    />
  ) : null;

  useEffect(() => {
    if (mapView?.current) {
      if (Platform.OS === 'web') {
        const bounds = new window.google.maps.LatLngBounds();
        [...mapProviderMarkers, episodeLocationMarker].map((item) => {
          bounds.extend(item.props.coordinates);
          return item.key;
        });
        //@ts-ignore
        mapView.current.fitBounds(bounds);
      } else {
        //@ts-ignore
        mapView.current.fitToElements({
          edgePadding: {
            top: theme.spacing,
            right: theme.spacing,
            bottom: theme.spacing,
            left: theme.spacing,
          },
        });
      }
    }
  }, [markers, mapView, episodeLocation, mapProviderMarkers]);

  if (!episodeLocation) return null;

  return (
    <Container testID={TestID.SelectYourDoctor.Map}>
      <MapViewPositionWrapper>
        <MapView
          ref={mapView}
          region={episodeLocation ? formatCoords(episodeLocation) : null}
          bounds
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            scrollwheel: false,
            streetViewControl: false,
            /**
             * Put zoom controls in bottom-right corner to avoid <LegendBar />
             * as well as <ExtendedOptionsNotification /> from overlapping.
             */
            zoomControlOptions: { position: 9 },
          }}
        >
          {mapProviderMarkers}

          {episodeLocationMarker}
        </MapView>
        <LegendBar />
      </MapViewPositionWrapper>
    </Container>
  );
};

export default Map;
