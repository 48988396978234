import styled, { css } from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';

import Anchor from 'app/components/Common/Anchor';
import CheckBox from 'app/components/Common/CheckBox';
import CheckBoxGroup from 'app/components/Common/CheckBoxGroup';
import { Field } from 'redux-form';
import { GooglePlacesInput } from 'app/components/Common/GooglePlacesInput';
import HtmlView from 'app/components/Common/HtmlView';
import Input from 'app/components/Common/Input';
import { LinearProgress } from 'react-native-elements';
import PhoneInput from 'app/components/Common/PhoneInput';
// TODO: import RadioGroup from 'app/components/Common/RadioGroup';
import RadioGroupButtonList from 'app/components/Common/RadioGroupButtonList';
import Select from 'app/components/Common/Select';
import SignatureInput from 'app/components/Common/SignatureInput';
import { ScrollView } from 'app/components/Common/Styled';
import { Text } from 'app/components/Common/Styled';
import TextArea from 'app/components/Common/TextArea';
import { View } from 'app/components/Common/Styled';
// @ts-ignore
import DatePicker from 'app/components/Common/DatePicker';

export { Component } from 'react';
export { default as ButtonList } from 'app/components/Common/ButtonList';
export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';
export { default as FormQuestion } from 'app/components/Forms/FormQuestion';
export { default as FormProgressIndicator } from 'app/components/Forms/FormProgressIndicator';
export { Routes } from 'app/util/routes';

export const FormQuestionHtmlContent = styled(HtmlView).attrs({
  scrollView: false,
})``;

const FormQuestionText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-family: ${theme.fontFamilyMedium};
    font-size: ${theme.fontSizes.body2}px;
  `}
`;

const gapWidth = 2; // ensure proportional spacing across components

/**
 * Passed as the `containerStyle` prop to various types of form field components.
 */
const inputContainerStyle = (theme) => ({
  marginBottom: theme.spacing * gapWidth, // Medium margin between adjacent inputs
  marginTop: (theme.spacing * gapWidth) / 2, // Small margin between label and input
});

const getMaxWidth = ({ breakpoints, windowDimensions }) => {
  switch (true) {
    case windowDimensions.width < breakpoints.xsmall:
      return windowDimensions.width;
    case windowDimensions.width <= breakpoints.xsmall:
      return breakpoints.xsmall;
    case windowDimensions.width <= breakpoints.small:
      return breakpoints.small;
    default:
      return breakpoints.medium;
  }
};

const getTopMargin = ({ breakpoints, spacing, windowDimensions }) => {
  switch (true) {
    case windowDimensions.width >= breakpoints.xlarge:
      return spacing * 3.5; // 24 + 56 === 80
    case windowDimensions.width >= breakpoints.medium:
      return spacing * 2.25; // 24 + 36 === 60
    default:
      return spacing * 2; // 24 + 32 === 56
  }
};

export { FormQuestionText };

export const Acknowledgement = {
  LabelText: FormQuestionText,

  HtmlContent: FormQuestionHtmlContent,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing * gapWidth}px;
    `}
  `,
};

export const CenteredScrollView = styled(ScrollView).attrs({
  keyboardAware: true,
})`
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  flex: 1 0 auto;
`;

export const ConstrainedWidthView = styled(View)`
  ${({ theme: { breakpoints, windowDimensions } }) => css`
    align-items: flex-start;
    align-self: center;
    max-width: ${getMaxWidth({ breakpoints, windowDimensions })}px;
    padding-horizontal: ${({ theme }) => `${theme.spacing * gapWidth}`}px;
    padding-bottom: ${({ theme }) => `${theme.spacing * 10}`}px;
    width: 100%;
  `}
`;

export const FormInputs = {
  Checkbox: styled(CheckBoxGroup).attrs(({ theme }) => {
    return {
      containerStyle: inputContainerStyle(theme),
    };
  })``,

  Address: GooglePlacesInput,
  Confirmation: CheckBox,
  PhoneNumber: PhoneInput,
  Radio: RadioGroupButtonList, // TODO: radio: RadioGroup, buttons: RadioGroupButtonList
  Select: Select,
  Signature: SignatureInput,
  String: Input,
  Text: TextArea,
  Date: DatePicker,
};

export const FormFadeaway = styled(LinearGradient).attrs(({ theme }) => ({
  colors: ['rgba(255,255,255,0)', theme.colors.sand],
  locations: [0, 0.5],
}))`
  ${({ theme }) => css`
    height: ${theme.spacing * 12}px;
    width: 100%;
    pointer-events: none;
    position: absolute;
    bottom: 0;
  `}
`;

export const FormLayout = {
  Column: styled(View)`
    ${({ theme }) => css`
      flex: 1;
      min-width: ${theme.spacing * 16}px;
    `}
  `,

  Question: styled(View)`
    align-self: flex-start;
    flex: 0 1 auto;
    width: 100%;
  `,

  Row: styled(View)`
    ${({ theme }) => css`
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: ${theme.spacing * gapWidth}px;
      width: 100%;
    `}
  `,
};

export const FormQuestionInputField = styled(Field).attrs(({ theme }) => {
  return {
    containerStyle: inputContainerStyle(theme),
  };
})``;

export const FormQuestionSignatureGuidanceText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.bodyText};
    font-size: ${theme.fontSizes.small}px;
    margin-bottom: ${theme.spacing / 3}px;
  `}
`;

export const LeftAlignedView = styled.View`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 ${theme.spacing / 4}px;
  `}
`;

export const QuestionWrapper = styled(View)`
  align-self: flex-start;
  flex: 0 1 auto;
  width: 100%;
`;

export const SaveLink = {
  Link: styled(Anchor).attrs(({ theme }) => {
    return {
      titleStyle: {
        fontFamily: theme.fontFamilyMedium,
        fontSize: theme.fontSizes.body2,
      },
    };
  })``,

  ProgressIndicator: styled(LinearProgress).attrs(({ theme }) => {
    return {
      color: theme.colors.lightBlue,
      trackColor: theme.colors.white,
      variant: 'determinate',
    };
  })`
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing * 2}px;
      margin-top: ${theme.spacing * 2}px;
    `}
  `,

  Wrapper: styled(View)`
    /* NOTE: margin-top is dependent on parent/grandparent padding. assumption? (t.s/2) / (t.s) */
    ${({ theme: { breakpoints, spacing, windowDimensions } }) => css`
      flex-direction: row;
      justify-content: flex-end;
      margin-top: ${getTopMargin({
        breakpoints,
        spacing,
        windowDimensions,
      })}px;
      width: 100%;
    `}
  `,
};
