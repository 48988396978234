import styled, { css } from 'styled-components/native';

import {
  AnchorContentProps,
  CardProps,
  MySettingsMenuRowProps,
  SettingsContainerProps,
} from './types';

import AnchorWithIcon from 'app/components/Common/AnchorWithIcon';
import { Avatar as RNEAvatar } from 'react-native-elements';
import { Card as CardRaw } from 'react-native-elements';
import { Icon } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Platform } from 'react-native';
import { Icon as RNEIcon } from 'react-native-elements';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { Text } from 'app/components/Common/Styled';
import { View } from 'app/components/Common/Styled';

export { default as AccountSettings } from './AccountSettings';

export { default as AccountSettingsForm } from 'app/components/Common/AccountSettingsForm';

export { default as AccountSettingsWrapper } from 'app/components/Common/Layout/CenteredScrollScreen';

export const AnchorContent = styled(ResponsivePressable).attrs({
  flexDirection: 'row',
})<AnchorContentProps>`
  ${({ isFirstRow, isFinalRow, theme }) => css`
    align-items: stretch;
    color: ${theme.colors.primary};
    flex: 1 0 auto;
    justify-content: space-between;
    padding-bottom: ${isFinalRow ? 0 : theme.spacing + 'px'};
    padding-top: ${isFirstRow ? 0 : theme.spacing + 'px'};
  `};
`;

export const AnchorIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.defaultDark,
  size: 40,
}))``;

export { default as AnchorSubText } from 'app/components/Common/Styled/Text';

export const AnchorText = styled.View`
  flex: 1 0 auto;
`;

export const AssociatedFieldLabel = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small}px;
  `};
`;

export const AssociatedFieldView = styled(View)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing}px;
    padding-right: ${Platform.OS === 'web' ? 0 : theme.spacing}px;
  `};
`;

export const Avatar = styled(RNEAvatar).attrs(({ theme }) => ({
  borderColor: theme.colors.default,
  borderWidth: 5,
  borderRadius: 50,
}))`
  ${({ theme }) => css`
    background-color: #fff;
    border-color: ${theme.colors.default};
    border-radius: 50px;
    border-width: 5px;
    height: 100px;
    width: 100px;
  `};
`;

export const AvatarButton = styled(ResponsivePressable).attrs({
  activeOpacity: 0.8,
})`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    flex: 1 1 auto;
    margin-bottom: ${theme.spacing * (theme.isNarrow ? 0.5 : 0.75)}px;
  `};
`;

export const BackLink = styled(AnchorWithIcon).attrs({
  icon: 'arrow-back',
})``;

export const BackLinkContainer = styled(View)`
  align-items: flex-start;
  justify-content: center;
  left: 0;
  top: 0;
`;

export const BackTitle = styled(Text)``;

export const BackgroundContainer = styled.View`
  ${({ theme }) => css`
    flex: 1 0 auto;
    height: 100%;
    margin: 0 auto;
    max-width: ${theme.breakpoints.xsmall + theme.spacing}px;
    width: 100%;
  `}
`;

export const CameraIcon = styled(RNEIcon).attrs(({ theme }) => ({
  color: theme.colors.default,
  name: 'camera-alt',
}))`
  ${({ theme }) => css`
    background-color: #fff;
    border-radius: 16px;
    padding: ${theme.spacing / 4}px;
  `};
`;

export const CameraIconContainer = styled.View`
  align-items: flex-end;
  border-radius: 72px;
  bottom: 0;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

export const Card = styled(CardRaw).attrs(({ theme }) => ({
  containerStyle: {
    flexGrow: 1,
    maxWidth: theme.breakpoints.xsmall,
  },
}))<CardProps>``;

export const CardContent = styled.View`
  ${({ theme }) => css`
    flex: 1 0 auto;
    padding: ${theme.spacing}px;
    padding-bottom: 0;
  `}
`;

export { default as CheckBox } from 'app/components/Common/CheckBox';

export const Container = styled.View`
  ${({ theme }) => css`
    flex-direction: row;
    justify-content: center;
    flex: 1 0 auto;
    margin-top: ${theme.spacing * (theme.isNarrow ? 0.5 : 1)}px;
  `};
`;

export const DetailsWrapper = styled.View`
  ${({ theme }) => css`
    left: 0;
    margin-top: ${theme.spacing * 1.5}px;
    position: ${Platform.select({ web: 'fixed', default: 'absolute' })};
    right: 0;
    top: ${theme.spacing}px;
  `};
`;

export const FormWrapper = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    padding: ${theme.spacing}px;
  `};
`;

export const FormContainer = styled.View`
  ${({ theme }) => css`
    margin: 0px ${theme.spacing}px;
  `}
`;

export const Header = styled(Text)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing}px;
  `};
`;

export { InputField } from '../Common/AccountSettingsForm/styles';

export const InputWrapper = styled(View)`
  ${({ theme }) => css`
    flex: 1 0 auto;
    margin-bottom: ${theme.spacing}px;
  `};
`;

export { default as Modal } from 'app/components/Common/Modal';

export { default as MySettingsContainer } from 'app/components/Common/Layout/CenteredScrollScreen';

export { default as MySettingsHome } from './MySettingsHome';

export const MySettingsMenuContainer = styled.View`
  display: flex;
  flex: 1 0 auto;
`;

export { default as MySettingsHeader } from 'app/components/MySettings/MySettingsHome//MySettingsHeader';

export { default as MySettingsMenu } from 'app/components/MySettings/MySettingsHome//MySettingsMenu';

export const MySettingsMenuRow = styled.View<MySettingsMenuRowProps>`
  ${({ isFinalRow, theme }) => css`
    border-bottom-color: ${theme.colors.default};
    border-bottom-width: ${isFinalRow ? '0' : '1'}px;
    flex: 1 0 auto;
  `};
`;

export { default as PersonalInformation } from './PersonalInformation';

export { default as PhoneInput } from 'app/components/Common/PhoneInput';

export { Platform };

export const ProcedureIconView = styled.View`
  ${({ theme }) => css`
    margin-right: ${theme.spacing / 4}px;
    padding: ${theme.spacing / 2}px;
  `}
`;

export { Route } from '@cross-platform/react-router-native';

export const Row = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const SectionHeaderContainer = styled(View)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

export { default as SectionHeader } from 'app/components/MySettings/SectionHeader';

export { default as Select } from 'app/components/Common/Select';

export { default as StickyNavFooter } from 'app/components/Common/StickyNavFooter';

export const Subtitle = styled(Text).attrs({
  numberOfLines: 1,
  size: 'body2',
})`
  text-align: center;
`;

export const SummaryContainer = styled.View<SettingsContainerProps>`
  flex: 1 0 auto;
  position: relative;
`;

export const SummaryInnerContainer = styled(KeyboardAwareScrollView).attrs(
  ({ theme }) => ({
    contentContainerStyle: {
      paddingBottom: theme.spacing * 4,
    },
  })
)``;

export { Switch } from '@cross-platform/react-router-native';

export { Text };

export const Title = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamilyBold};
    font-size: ${theme.isNarrow
      ? theme.rawFontSizes.medium
      : theme.rawFontSizes.xlarge}px;
    margin: ${theme.spacing}px 0;
  `};
`;

export const TitleWrapper = styled.View`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    flex: 1 0 auto;
    margin-bottom: ${theme.spacing * 6}px;
    overflow: visible;
    padding: ${theme.spacing}px;
  `};
`;

export { View };
