import { Component } from 'react';

import { FaqSection } from 'app/components/LearnMore/subcomponents';
import { faqSections } from 'app/components/LearnMore/constants';
import { FaqStyles as Styled } from 'app/components/LearnMore/styles';

export class Faq extends Component {
  state = {
    activeSection: null,
  };

  toggleSection = (index) => {
    this.setState({
      activeSection: this.state.activeSection === index ? null : index,
    });
  };

  render() {
    return (
      <Styled.Wrapper testID="Faq">
        {faqSections.map(({ title, body }, index) => (
          <FaqSection
            key={title}
            body={body}
            title={title}
            active={this.state.activeSection === index}
            onToggle={() => this.toggleSection(index)}
          />
        ))}
      </Styled.Wrapper>
    );
  }
}
