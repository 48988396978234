export const faqSections = [
  {
    title: 'Why Carrum Health?',
    body: [
      "Carrum Health connects you with top-rated providers who deliver the best possible outcomes and provide exceptional care. When you receive treatment at a facility in Carrum's network, your costs are often fully covered after you meet your plan's deductible or co-insurance requirements, if applicable.",
    ],
  },
  {
    title: 'What’s covered?',
    body: [
      'Carrum Health covers a wide range of non-emergency healthcare services from top doctors and treatment centers in the country who specialize in a variety of conditions. Your access to these services depends on your employer’s plan coverage. To learn which services are available to you, log in to your account or call Carrum Health at 1-888-855-8706.',
    ],
  },
  {
    title: 'Who is eligible?',
    body: [
      'To be eligible for Carrum Health benefits, you must be at least 18 years old and covered as either the primary subscriber or a dependent on a qualifying employer-sponsored health plan. To find out if you or your dependents are eligible, log in to your account or call Carrum Health at 1-888-855-7806.',
    ],
  },
  {
    title: 'Helping you throughout your journey',
    body: [
      "If you've been recommended for a qualifying healthcare service, contact Carrum Health. We’ll help coordinate your care and guide you every step of the way.",
    ],
  },
  {
    title: 'How do I get started?',
    body: [
      'To get started, simply call us at 1-888-855-7806 or log in to your Carrum Health account and follow the prompts. Let us know the type of care you’d like to receive, and from there, you’ll be guided with easy-to-follow instructions. If you ever have any questions, just message or call us to speak with a Carrum care specialist.',
    ],
  },
  {
    title: 'How do I delete my account?',
    body: [
      'If you’ve created a Carrum Health account and later decide you’d like to delete your account, simply send a message in the Carrum Patient App requesting your account to be deleted. Your account will be deleted within 30 days from the time of the request.',
      'Please note:',
      [
        'When you delete your Carrum account, your chat history, profile, and forms will be removed.',
        'If you wish to use Carrum’s services in the future after deleting your account, you can register again.',
      ],
    ],
  },
];
