import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer from 'app/reducers';
import { State } from 'types/state';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export default createStore<State.RootState>(
  rootReducer,
  applyMiddleware(...middlewares)
);
