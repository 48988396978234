import { FaqSectionStyles as Styled } from 'app/components/LearnMore/styles';
import { FaqSectionProps } from 'app/components/LearnMore/types';

export const FaqSection = ({
  active: isActive,
  body,
  onToggle,
  title,
}: FaqSectionProps) => (
  <Styled.Wrapper>
    <Styled.Title.Wrapper onPress={onToggle}>
      <Styled.Title.Text>{title}</Styled.Title.Text>
      {/* @ts-expect-error no overload matches this call */}
      <Styled.Title.ArrowIcon active={isActive} />
    </Styled.Title.Wrapper>

    {isActive && <Styled.Divider />}

    {isActive && (
      <Styled.Body.Wrapper>
        {body.map((section) =>
          Array.isArray(section) ? (
            <Styled.Body.FaqList key={section.join(',')} items={section} />
          ) : (
            <Styled.Body.Text key={section}>{section}</Styled.Body.Text>
          )
        )}
      </Styled.Body.Wrapper>
    )}
  </Styled.Wrapper>
);
