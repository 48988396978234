import { ENVIRONMENT } from 'app/util/constants';

import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

// Specify different application IDs for different platforms.
const DATADOG_APPLICATION_ID = Platform.select({
  web: '6a0509d8-1bd0-4850-8875-a1881640ad99',
  native: 'c59f7a28-d99d-43e9-9516-560e1b14e580',
});

// Specify different client tokens for different platforms.
const DATADOG_CLIENT_TOKEN = Platform.select({
  web: 'pubad291d795bd23182dce0856e32d47a8a',
  native: 'pubaef0551fe7481837f0ad2bbdbfb8821b',
});

// Use different settings to differentiate logs by platform.
const DATADOG_SERVICE = Platform.select({
  web: 'patient-app-web',
  native: 'patient-app-mobile',
});

// Documentation seems to suggest different values be sent to initialize the
// web and native SDKs.
//
// - https://docs.datadoghq.com/getting_started/site/
// - https://docs.datadoghq.com/logs/log_collection/javascript/
// - https://docs.datadoghq.com/logs/log_collection/reactnative/#batch-collection
const DATADOG_SITE = Platform.select({
  web: 'datadoghq.com',
  native: 'US1',
});

// Specify configuration settings that are supported by both the native and web
// implementations. Note that several properties vary based on the platform.
// Note also that this baseline configuration may be extended in the
// `app/config/datadog.*.ts` files to include settings that are only applicable
// for a specific platform (such as `nativeCrashReportEnabled` for the native
// application only).
export const DATADOG_CONFIG = {
  // specify 3 essential details (`clientToken`, `env`, and `applicationId`)
  clientToken: DATADOG_CLIENT_TOKEN,
  env: ENVIRONMENT,
  applicationId: DATADOG_APPLICATION_ID,
  // specify other optional properties to enable/disable features as needed
  service: DATADOG_SERVICE,
  site: DATADOG_SITE,
  sessionSampleRate: 100, // sample % of sessions to record and send for RUM
  sessionReplaySampleRate: 20, // sample x% of interactions (click, scroll, mouse, etc.) to support a visual "replay"
  resourceTracingSampleRate: 100, // only link x% of (front-end) RUM views to (back-end) APM views in prod
  trackErrors: true,
  trackFrustrations: true, // track rage clicks, dead clicks, and error clicks
  trackLongTasks: true, // whether RUM captures tasks that block the main thread and degrade performance and the UX (jank, slow responsiveness)
  trackResources: true, // whether RUM captures network requests (API calls, images, etc.)
  trackUserInteractions: true, // whether RUM captures user-triggered events (clicks, scrolls, mouse movements, and other)
  defaultPrivacyLevel: 'mask-user-input' as DefaultPrivacyLevel, // obfuscate and anonymize session and user interaction data; see https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/
};

// Log errors using Datadog when running in non-development mode, including
// staging and production builds of the web and native app.
//
// Do not launch in a simulator when running E2E tests against the staging
// environment.
export const DATADOG_ENABLED = ENVIRONMENT !== 'development' && Device.isDevice;

// Datadog automatically correlates RUM events and browser logs if the
// configurations for the RUM Browser SDK and Logs SDK match. Use session
// and/or view IDs to correlate logs with RUM events (`session_id` and/or
// `view.id`.
//
// Logs are first stored on the local device in batches and kept until
// conditions are met so they can be safely sent.
//
// - https://docs.datadoghq.com/logs/guide/ease-troubleshooting-with-cross-product-correlation/#correlate-frontend-products
// - https://docs.datadoghq.com/account_management/billing/rum/#how-do-you-view-logs-from-the-browser-collector-in-rum
// - https://docs.datadoghq.com/logs/log_collection/reactnative/#batch-collection
export const DATADOG_LOGS_CONFIG = {
  // properties shared with base config
  ...{
    applicationId: DATADOG_CONFIG.applicationId,
    clientToken: DATADOG_CONFIG.clientToken,
    env: DATADOG_CONFIG.env,
    site: DATADOG_CONFIG.site,
    service: DATADOG_CONFIG.service,
    sessionSampleRate: DATADOG_CONFIG.sessionSampleRate,
  },
  // properties specific to browser logging
  ...{
    forwardConsoleLogs: ['all'],
    forwardErrorsToLogs: true,
  },
};

// To associate additional details using the native and browser SDKs, pass the
// following configuration to the `DdSdkReactNative.setAttributes()` and
// `datadogRum.setGlobalContext` methods.
export const DATADOG_TAGS_AND_CONTEXT = {
  // @ts-expect-error Property does not exist
  expoReleaseChannel: Updates.manifest.releaseChannel,
  // @ts-expect-error Property does not exist
  appVersion: Updates.manifest.version,
  // @ts-expect-error Property does not exist
  appPublishedTime: Updates.manifest.publishedTime,
  // @ts-expect-error Property does not exist
  expoSdkVersion: Updates.manifest.sdkVersion,
  deviceId: Constants.sessionId,
  appOwnership: Constants.appOwnership || 'N/A',
  expoAppVersion: Constants.expoVersion,
  expoChannel: Updates.channel,
  manifest: Updates.manifest,
  deviceYearClass: Device.deviceYearClass,
  linkingUri: Constants.linkingUri,
};
