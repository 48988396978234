import { HeaderStyles as Styled } from 'app/components/Messages/styles';

import { HeaderProps } from 'app/components/Messages/types';

import { Participants } from './subcomponents';

export const Header = ({
  hasConversation = false,
  participants,
}: HeaderProps) => {
  const title = hasConversation ? 'My Care Team' : 'Talk to Us';

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Title.Wrapper>
          <Styled.Title.Text>{title}</Styled.Title.Text>
        </Styled.Title.Wrapper>

        <Participants
          isRendered={hasConversation}
          participants={participants}
        />
      </Styled.Content>
    </Styled.Wrapper>
  );
};
