import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Modal as ModalRaw, ModalProps } from 'app/components/Common/Modal';
export { default as Messages } from 'app/components/Messages';
import { View } from 'app/components/Common/Styled';

export const Modal = styled(ModalRaw).attrs(
  ({ theme }) =>
    ({
      scrollable: false,
      size: 'xlarge',
      overlayStyleOverrides: {
        height: theme.windowDimensions.height - theme.spacing * 6.5,
      },
    } as ModalProps)
)<ModalProps>``;

export const InnerContainer = styled(View)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: ${theme.spacing / 2}px;
    margin-right: ${theme.spacing / 2}px;
  `}
`;
