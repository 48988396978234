import styled, { css } from 'styled-components/native';

import { Icon } from 'react-native-elements';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { ScrollView } from 'react-native';
import { Text } from 'react-native-elements';
import UnorderedList from 'app/components/Common/UnorderedList';
import { View } from 'react-native';

export const LearnMoreStyles = {
  Header: styled(Text).attrs(({ theme }) => {
    return {
      h1: true,
      h1Style: {
        color: theme.colors.bodyText,
        marginBottom: theme.spacing * 1.5,
        paddingHorizontal: theme.spacing / 2,
      },
    };
  })``,

  Text: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.bodyText};
      font-size: ${theme.fontSizes.body2}px;
      margin-bottom: ${theme.spacing * 2.25}px;
      text-align: center;
    `}
  `,

  Wrapper: styled(ScrollView)`
    ${({ theme }) => css`
      align-self: center;
      max-width: ${theme.breakpoints.small}px;
      width: 100%;
    `}
  `,
};

export const FaqSectionStyles = {
  Divider: styled(View)`
    ${({ theme }) => css`
      border-color: ${theme.colors.default};
      border-style: dashed;
      border-width: 1px;
      height: 1px;
      margin-bottom: ${theme.spacing / 2}px;
      width: 100%;
    `}
  `,

  Body: {
    FaqList: styled(UnorderedList).attrs(({ theme }) => ({
      containerStyle: {
        marginBottom: theme.spacing,
      },
      itemStyle: {
        marginBottom: theme.spacing * 0.75,
        fontSize: theme.fontSizes.body2,
      },
    }))`
      ${({ theme }) => css`
        font-size: ${theme.fontSizes.body2}px;
      `}
    `,

    Text: styled(Text)`
      ${({ theme }) => css`
        font-size: ${theme.fontSizes.body2}px;
        margin: 0 ${theme.spacing * 1.25}px ${theme.spacing}px;
      `}
    `,

    Wrapper: styled(View)`
      padding: ${({ theme }) => theme.spacing}px;
      width: 100%;
    `,
  },

  Title: {
    // @ts-expect-error Property 'active' does not exist on type
    ArrowIcon: styled(Icon).attrs(({ active, theme }) => ({
      name: active ? 'keyboard-arrow-up' : 'keyboard-arrow-down',
      size: theme.spacing * 2.5,
    }))``,

    Text: styled(Text)`
      ${({ theme }) => css`
        flex: 1 1 auto;
        font-size: ${theme.fontSizes.body2}px;
      `}
    `,

    Wrapper: styled(ResponsivePressable)`
      ${({ theme }) => css`
        align-items: center;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: flex-start;
        padding: ${theme.spacing * 1.25}px ${theme.spacing * 1.25}px 0;
        width: 100%;
      `}
    `,
  },

  Wrapper: styled(View)`
    ${({ theme }) => css`
      align-items: center;
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.default};
      border-width: 1px;
      flex: 1 0 auto;
      flex-direction: column;
      margin: ${theme.spacing * 0.75}px;
      margin-bottom: 0px;
    `}
  `,
};

export const FaqStyles = {
  Wrapper: styled(View)`
    ${({ theme }) => css`
      flex: 1 0 auto;
      padding-bottom: ${theme.spacing * 5}px;
    `}
  `,
};
