import { ParticipantsStyles as Styled } from 'app/components/Messages/styles';
import { ParticipantsProps } from 'app/components/Messages/types';
import { Participant } from './subcomponents';

export const Participants = ({
  isRendered,
  participants,
}: ParticipantsProps) => {
  if (!isRendered) return null;

  return (
    <Styled.Wrapper horizontal>
      {participants.map(({ id, name, status }) => (
        <Participant key={id} id={id} name={name} status={status} />
      ))}
    </Styled.Wrapper>
  );
};
