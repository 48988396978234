import axios from 'axios';
import Constants from 'expo-constants';

import store from 'app/util/store';
import { invalidateSession } from 'app/actions/sessionActions';

const appVersion = Constants.expoConfig.version;

// Handle session expiration while using the app.
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      store.getState().session.coreToken &&
      error.response &&
      error.response.status === 401
    ) {
      store.dispatch(invalidateSession());
    }

    return Promise.reject(error);
  }
);

// Include app version in all requests.
axios.interceptors.request.use((config) => {
  // Apply custom header to all internal API requests
  if (config.url.indexOf('.carrumhealth.com') > -1) {
    config.headers['Carrum-Mobile-App-Version'] = appVersion;
  }
  return config;
});
