// @ts-expect-error Cannot find module 'app/assets/images/anonymous.jpg'
export { default as defaultAuthorProfileImage } from 'app/assets/images/anonymous.jpg';

export { EVENTS } from 'app/util/analytics';

export const initialFormValues = { body: '', attachments: [] };

export const messagesPerPage = 20;

const holidayMessageText = {
  standard:
    'Notice: We are closed for the Holiday and will respond to all messages the next business day. Please give us a call at 888-855-7806 if you need immediate assistance.',
  thanksgiving:
    'Notice: We are closed for the Thanksgiving holiday on 11/27 and 11/28, and will respond to all messages the next business day. Please call us at 888-855-7806 if you need immediate assistance.',
  shutdown:
    "Notice: We're currently operating at half capacity from 12/11 to 1/1, which may result in a slightly longer response time. We will get back to you as quickly as we can. Please call us at 888-855-7806 if you need immediate assistance.",
};

// This data is updated annually when the new holiday calendar is released.
export const scheduledHolidays = [
  {
    date: { starting: '2025-01-20', ending: '2025-01-20' }, // Martin Luther King Jr. Day
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-02-17', ending: '2025-02-17' }, // Presidents' Day
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-04-18', ending: '2025-04-18' }, // Easter
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-05-26', ending: '2025-05-26' }, // Memorial Day
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-06-20', ending: '2025-06-20' }, // Juneteenth
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-07-04', ending: '2025-07-04' }, // Independence Day
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-09-01', ending: '2025-09-01' }, // Labor Day
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-10-10', ending: '2025-10-10' }, // World Mental Health Day
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-11-11', ending: '2025-11-11' }, // Veteran's Day
    messageText: holidayMessageText.standard,
  },
  {
    date: { starting: '2025-11-27', ending: '2025-11-28' }, // Thanksgiving
    messageText: holidayMessageText.thanksgiving,
  },
  {
    date: { starting: '2025-12-11', ending: '2026-01-02' }, // Holiday Shutdown
    messageText: holidayMessageText.standard,
  },
];

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';
