import { Platform } from 'react-native';

import { datadogRum } from '@cross-platform/datadog-rum/index';

import { logScreenView } from 'app/util/analytics';
import history from 'app/util/history';

history.listen((location) => {
  // log navigation events
  logScreenView(location.pathname); // in Google Analytics
  datadogRum.startView(location.pathname, location.pathname); // in Datadog RUM

  // ensure we wait for render to scroll on subsequent content
  if (Platform.OS === 'web') {
    setTimeout(() => {
      // reset the scroll position when navigating in web browsers
      window?.scrollTo(0, 0); // this is because of RN web's injected global scroll component
    }, 0);
  }
});
