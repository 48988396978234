import React, { Component } from 'react';
import { Platform } from 'react-native';

import { LearnMoreStyles as Styled } from './styles';
import { Faq } from './subcomponents';

export default class LearnMore extends Component {
  state = {
    selectedIndex: 0,
  };

  updateIndex = (selectedIndex) => {
    this.setState({ selectedIndex });
  };

  render() {
    return (
      <Styled.Wrapper testID="LearnMore">
        <Styled.Header>Learn More About Carrum</Styled.Header>

        {Platform.OS === 'web' && (
          <Styled.Text>
            For more information, call us at 1-888-855-7806.
          </Styled.Text>
        )}

        <Faq />
      </Styled.Wrapper>
    );
  }
}
