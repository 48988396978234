import {
  DATADOG_CONFIG,
  DATADOG_ENABLED,
  DATADOG_LOGS_CONFIG,
  DATADOG_TAGS_AND_CONTEXT,
} from 'app/util/datadog';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

/**
 * Configure and initialize the Datadog SDK with custom tagging. Enable real-
 * user monitoring (RUM), application performance monitoring (APM), crash
 * reporting, and more.
 *
 * - https://docs.datadoghq.com/real_user_monitoring/browser/setup/client/?tab=rum
 * - https://docs.datadoghq.com/real_user_monitoring/browser/data_collected/
 * - https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm
 * - https://github.com/DataDog/browser-sdk/blob/main/README.md
 */
(async () => {
  if (!DATADOG_ENABLED) return;

  try {
    datadogRum.init(DATADOG_CONFIG);

    // Send logs to datadog from web browser pages.
    //
    // - https://docs.datadoghq.com/logs/log_collection/javascript/
    //
    // @ts-expect-error Argument of type (for `forwardConsoleLogs`, it rejects boolean and string with contradictory messages)
    datadogLogs.init(DATADOG_LOGS_CONFIG);

    // Add custom tags to traces/logs
    //
    // - https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#view-context
    // - https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#global-context
    datadogRum.setGlobalContext({
      ...datadogRum.getGlobalContext(),
      ...DATADOG_TAGS_AND_CONTEXT,
    });
  } catch (error) {
    console.log('Unable to initialize Datadog', error);
  }
})();
